<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :buttonShow="false"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
    >
      <!-- <div slot="toolSub">
        <el-button
          type="success"
          :loading="bntLoading"
          icon="el-icon-plus"
          @click="synchronism"
          >同步默认商品</el-button
        >
      </div> -->
      <div class="search" slot="subSlot">
        <el-input
          placeholder="根据名称查询"
          v-model="searchQuery.siteName"
          class="searchInput"
          size="small"
        >
          <template slot="prepend">名称</template>
        </el-input>

        <el-input
          placeholder="根据电报码查询"
          v-model="searchQuery.siteCode"
          class="searchInput"
          size="small"
        >
          <template slot="prepend">电报码</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      pswDialogVisible: false,
      remote: api.common,
      apiName: "/storeAaddress",
      searchVal: "",
      bntLoading: false,
      searchQuery: { siteName: "", siteCode: "" },
      rules: {
        //传递给APPlist的表单验证
      },
      props: [
        {
          label: "姓名",
          prop: "name",
          align: "center",
        },

        {
          label: "类型",
          prop: "type",
          align: "center",
          type: "html",
          formatter: function (row) {
            const statusMap = [
              { class: "blue", text: "发货地址" },
              { class: "", text: "退货地址" },
            ];
            return (
              "<span  class='" +
              statusMap[row.type].class +
              "'>" +
              statusMap[row.type].text +
              "</span>"
            );
          },
        },
        {
          label: "电话",
          prop: "phone",
          align: "center",
        },

        {
          label: "地址",
          prop: "details",
          align: "center",
          formatter: function (row) {
            let code = row.contactAddress + "";
            let contactAddress =
              CodeToText[code.substring(0, 3) + "000"] +
              CodeToText[code.substring(0, 4) + "00"] +
              CodeToText[code];
            return contactAddress + row.details;
          },
        },
        {
          label: "默认",
          prop: "isdefault",
          align: "center",
        },

        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "姓名",
          type: "input",
          prop: "name",
        },
        {
          label: "电话",
          type: "input",
          prop: "phone",
        },
        {
          label: "类型",
          type: "radio",
          prop: "type",
          default: 0,
          selectData: [
            { label: "发货地址", value: 0 },
            { label: "退货地址", value: 1 },
   
          ],
        },

        {
          label: "联系地址",
          type: "cascader",
          prop: "contactAddress",
          options: regionData,
        },
        {
          label: "详细地址",
          type: "input",
          prop: "details",
        },
        {
          label: "是否默认",
          type: "radio",
          default: 0,
          selectData: [
            { label: "是", value: 0 },
            { label: "否", value: 1 },
          ],
          prop: "isdefault",
        },
      ],
    };
  },
  created() {},
  mounted() {
    let codex = console.log(codex);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
